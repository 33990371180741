require('dotenv').config({
  path: `.env.${process.env.NODE_ENV}`,
});

const projectId = process.env.GATSBY_PROJECT_ID;
const dataset = process.env.GATSBY_DATASET;

// if (!process.env.PROJECT_ID) {
//   projectId = process.env.GATSBY_SANITY_PROJECT_ID;
//   dataset = process.env.GATSBY_SANITY_DATASET;
// }

export default {
  projectId: projectId,
  dataset: dataset,
};
